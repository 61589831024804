import { Button } from '@primer/react'
import React, { useEffect } from 'react'
import { Heading, SplitPageLayout, TextInput, TabNav } from '@primer/react'
import Name from '../components/name'
import { getName } from '../service/api'
import Loading from '../components/loading';


const NameIT = () => {
  const handleGenerate = async () => {
    setLoading(true)
    const responsefromAPI = await getName(amid);
    const bundle = responsefromAPI.response.bundle;
    const image = responsefromAPI.response.image;
    const description = responsefromAPI.response.description || '';
    const names = responsefromAPI.response.names.filter((name: any) => !!name.name).map((name: any) => name.name);
    setGeneratedNames(names)
    setImage(image)
    setDescription(description)
    setBundle(bundle)
    setShowGenerate(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)


  }
  const [seeSaved, setSeeSaved] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [showGenerate, setShowGenerate] = React.useState(false)
  const [saved, setSaved] = React.useState<any>([])
  const [description, setDescription] = React.useState<any>([])
  const [bundle, setBundle] = React.useState<any>({})
  const [image, setImage] = React.useState<any>('')
  const [generatedNames, setGeneratedNames] = React.useState<any>([])
  const [amid, setAmid] = React.useState<any>('')
  const handleOnSave = (name: string) => {
    if (saved.includes(name)) {
      setSaved(saved.filter((item: string) => item !== name))
    } else {
      setSaved([...saved, name])
    }

  }

  const handleOnShowMore = (name: string) => {
    console.log('NameIT: handleOnSave', name)
  }
  const handleOnRemove = (name: string) => {
    console.log('NameIT: handleOnSave', name)
  }

  if (loading) {
    return <Loading />
  }


  return <SplitPageLayout>
    <SplitPageLayout.Header>
      <Heading sx={{ fontSize: 5, mb: 2 }} as={'h1'}>Customize your product:</Heading>
    </SplitPageLayout.Header>
    <SplitPageLayout.Content>
      {showGenerate && <div>
        <TabNav aria-label="Main" sx={{ mb: 5, cursor: 'pointer' }}>
          <TabNav.Link onClick={() => setSeeSaved(false)} selected={!seeSaved}>
            Generated
          </TabNav.Link>
          <TabNav.Link onClick={() => setSeeSaved(true)} selected={seeSaved}>Saved({saved.length})</TabNav.Link>
        </TabNav>

        <div className='d-flex'>
          <div className='flex-column'>
            <Heading sx={{ fontSize: 5, mb: 5 }} as={'h1'}>Names:</Heading>
            {!seeSaved ? generatedNames.map((name) => <Name key={name} name={name} saved={saved.includes(name)} onSave={handleOnSave} onShowMore={handleOnShowMore} onRemove={handleOnRemove} />) : saved.map((name) => <Name name={name} key={name} saved={true} onSave={handleOnSave} onShowMore={handleOnShowMore} onRemove={handleOnRemove} />)}
            {description && <>
              <Heading sx={{ fontSize: 5, mb: 2 }} as={'h1'}>Description:</Heading>
              <div className="description-2">{description}</div>
            </>}
          </div>
          <div className='flex-column'>
            <div className='product product-sin-badge'>
              <div className="product-image">
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        </div>




      </div>}
    </SplitPageLayout.Content>
    <SplitPageLayout.Pane>
      <div className='form-input'>
        <TextInput aria-label="AMID" onChange={(ev) => setAmid(ev.target.value)} name="amid" placeholder="AMID" />
        <Button onClick={handleGenerate}>Generate</Button>
      </div>
      {showGenerate && <div className="product">
        <div className="product-image">
          <img src={bundle.image} alt="" />
        </div>
        <div className="product-description">
          {bundle.details}
        </div>
        <div className="product-tags">
          <div className="product-tags__tag product-tags__tag--sustainable">Sustainable</div>
          <div className="product-tags__tag">{bundle.amid}</div>
          <div className="product-tags__tag">{bundle.color}</div>
          <div className="product-tags__tag">{bundle.fabrication}</div>
          <div className="product-tags__tag">{bundle.lifestyle}</div>
          <div className="product-tags__tag">{bundle.shape}</div>
          <div className="product-tags__tag">{bundle.type}</div>
        </div>
      </div>
      }
    </SplitPageLayout.Pane>
    {/* <SplitPageLayout.Footer>
      <div className="footer"><div className="d-flex js-build-in-trigger build-in-animate" data-build-in-stagger="100">
        <div className="col-1 d-flex flex-column flex-items-center">
          <div className="mb-4 position-relative home-campaign-glowing-icon js-build-in-item build-in-scale-fade build-in-animate td0">
            <svg aria-hidden="true" height="24" viewBox="0 0 24 24" version="1.1" width="24" data-view-component="true" className="octicon octicon-briefcase">
              <path d="M7.5 1.75C7.5.784 8.284 0 9.25 0h5.5c.966 0 1.75.784 1.75 1.75V4h4.75c.966 0 1.75.784 1.75 1.75v14.5A1.75 1.75 0 0 1 21.25 22H2.75A1.75 1.75 0 0 1 1 20.25V5.75C1 4.784 1.784 4 2.75 4H7.5Zm-5 10.24v8.26c0 .138.112.25.25.25h18.5a.25.25 0 0 0 .25-.25v-8.26A4.235 4.235 0 0 1 18.75 13H5.25a4.235 4.235 0 0 1-2.75-1.01Zm19-3.24v-3a.25.25 0 0 0-.25-.25H2.75a.25.25 0 0 0-.25.25v3a2.75 2.75 0 0 0 2.75 2.75h13.5a2.75 2.75 0 0 0 2.75-2.75Zm-6.5-7a.25.25 0 0 0-.25-.25h-5.5a.25.25 0 0 0-.25.25V4h6Z"></path>
            </svg>
            <span className="glow-3 position-absolute left-0 top-0 height-full width-full home-campaign-glowing-icon-glow z-n1"></span>
          </div>

          <div className="home-campaign-git-line height-full rounded js-build-in-item build-in-scale-top build-in-animate"></div>
        </div>

        <div className="col-8 col-lg-9 ml-2 ml-md-0 mb-6 mb-md-10">
          <h2 className="h5-mktg mb-4 text-medium js-build-in-item build-in-slideX-left build-in-animate td200" >Productivity</h2>
          <h3 className="h3-mktg mb-4 text-medium js-build-in-item build-in-slideX-left build-in-animate td300" >
            <span className="text-accent-primary">Accelerate high-quality creative development.</span>
            {' '} Our AI-powered platform drives innovation with tools that boost creative velocity.
          </h3>
          <img className='feature-image' src={NameItImage} alt="" />
        </div>
      </div>
        <div className="d-flex js-build-in-trigger build-in-animate" data-build-in-stagger="100">
          <div className="col-1 d-flex flex-column flex-items-center">
            <div className="mb-4 position-relative home-campaign-glowing-icon js-build-in-item build-in-scale-fade build-in-animate td0">
              <svg aria-hidden="true" height="24" viewBox="0 0 24 24" version="1.1" width="24" data-view-component="true" className="octicon octicon-code-of-conduct">
                <path d="M2.828 4.328C5.26 1.896 9.5 1.881 11.935 4.317c.024.024.046.05.067.076 1.391-1.078 2.993-1.886 4.777-1.89a6.22 6.22 0 0 1 4.424 1.825c.559.56 1.023 1.165 1.34 1.922.318.756.47 1.617.468 2.663 0 2.972-2.047 5.808-4.269 8.074-2.098 2.14-4.507 3.924-5.974 5.009l-.311.23a.752.752 0 0 1-.897 0l-.312-.23c-1.466-1.085-3.875-2.869-5.973-5.009-2.22-2.263-4.264-5.095-4.27-8.063a6.216 6.216 0 0 1 1.823-4.596Zm8.033 1.042c-1.846-1.834-5.124-1.823-6.969.022a4.712 4.712 0 0 0-1.382 3.52c0 2.332 1.65 4.79 3.839 7.022 1.947 1.986 4.184 3.66 5.66 4.752a78.214 78.214 0 0 0 2.159-1.645l-2.14-1.974a.752.752 0 0 1 1.02-1.106l2.295 2.118c.616-.52 1.242-1.08 1.85-1.672l-2.16-1.992a.753.753 0 0 1 1.021-1.106l2.188 2.02a18.963 18.963 0 0 0 1.528-1.877l-.585-.586-1.651-1.652c-1.078-1.074-2.837-1.055-3.935.043-.379.38-.76.758-1.132 1.126-1.14 1.124-2.96 1.077-4.07-.043-.489-.495-.98-.988-1.475-1.482a.752.752 0 0 1-.04-1.019c.234-.276.483-.576.745-.893.928-1.12 2.023-2.442 3.234-3.576Zm9.725 6.77c.579-1.08.92-2.167.92-3.228.002-.899-.128-1.552-.35-2.08-.22-.526-.551-.974-1.017-1.44a4.71 4.71 0 0 0-3.356-1.384c-1.66.004-3.25.951-4.77 2.346-1.18 1.084-2.233 2.353-3.188 3.506l-.351.423c.331.332.663.664.993.998a1.375 1.375 0 0 0 1.943.03c.37-.365.748-.74 1.125-1.118 1.662-1.663 4.373-1.726 6.06-.045.56.558 1.12 1.12 1.658 1.658Z"></path>
              </svg>
              <span className="glow-4 position-absolute left-0 top-0 height-full width-full home-campaign-glowing-icon-glow z-n1"></span>
            </div>

            <div className="home-campaign-git-line line-color-2 height-full rounded js-build-in-item build-in-scale-top build-in-animate"></div>
          </div>

          <div className="col-12 col-lg-9 ml-2 ml-md-0 mb-6 mb-md-10">
            <div className='col-8'>
              <h2 className="h5-mktg mb-4 text-medium js-build-in-item build-in-slideX-left build-in-animate td200" >Automation</h2>
              <h3 className="h3-mktg mb-4 text-medium js-build-in-item build-in-slideX-left build-in-animate td300" >
                <span className="text-orange-primary">Automate all image modification processes</span>
                {' '} Using AI, generate on the fly if a product falls into specific categories and generate the image badge.
              </h3>
            </div>
            <div className='d-flex flex-wrap'>
              <img className='badge-image' src={Badge1} alt="" />
              <img className='badge-image' src={Badge2} alt="" />
              <img className='badge-image' src={Badge3} alt="" />
              <img className='badge-image' src={Badge4} alt="" />
            </div>
          </div>

        </div>

        <div className="position-relative overflow-hidden" data-color-mode="dark" data-light-theme="light" data-dark-theme="dark">


          <div className="container-xl position-relative z-2">
            <div className="river-mktg js-build-in-trigger d-flex gutter gutter-spacious my-5 my-sm-7 my-md-8 position-relative flex-md-items-center text-center flex-column build-in-animate" data-build-in-stagger="100">
              <div className="col-12 py-3 mb-2">
                <div className="">
                  <h2 className="color-fg-default mb-3 h3-mktg col-lg-8 mx-md-auto px-3">Schnitzel: The place for anyone from anywhere to&nbsp;create&nbsp;anything</h2>
                  <p className="p-final mb-3 f3-mktg color-fg-muted px-3 mx-auto col-7-max">Whether you’re creating products, or you are late with your Culture Book Submission, or the dreaded SAR period is almost over and you have not created your Memo!</p>
                  <Button className="mx-auto main-button" size="large" block >Get Snitzy!</Button>
                </div>
              </div>
            </div>  </div>
        </div>
      </div>
    </SplitPageLayout.Footer> */}
  </SplitPageLayout>
}

export default NameIT
