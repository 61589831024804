import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import App from "./App";
import ErrorPage from "./pages/error.tsx";
import Badger from "./pages/badger.tsx";
import NameIT from "./pages/nameit.tsx";
import Offers from "./pages/offers.tsx";
import HomePage from "./pages/home.tsx";
import Sar from "./pages/sar.tsx";
const router = createBrowserRouter([
  {
    path: "/",
    Component: HomePage,
    errorElement: <ErrorPage />,
  },
  {
    path: "app",
    Component: App,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "badger",
        element: <Badger />,
      },
      {
        path: "name-it",
        element: <NameIT />,
      },
      {
        path: "offers-ai",
        element: <Offers />,
      },
      {
        path: "sar",
        element: <Sar />,
      },
    ],
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
