const mockNameResponse = {
  "message": "good",
  "response": {
    "Bundle": {
      "amid": "PCT148508",
      "image": "https://media-resize.adoreme.com/resize/420/gallery/2023/5/5yv99bmhf_lizzie-medium-pink-plus/full.jpeg",
      "color": "Blue",
      "shape": "slip",
      "type": "lingerie",
      "fabrication": "lace",
      "lifestyle": "romantic",
      "details": "has lace plunge back detail, Back self-tie closure, Above the knee length"
    },
    "Names": [
      {
        "Name": "",
        "Explanation": ""
      },
      {
        "Name": "Aurora",
        "Explanation": " is a beautiful name that means \"dawn\" or \"the rising of the sun.\" It is perfect for a romantic lingerie piece that is made of blue lace.\n"
      },
      {
        "Name": "Clara",
        "Explanation": " is a classic name that means \"clear\" or \"bright.\" It is a good choice for a lingerie piece that is simple and elegant.\n"
      },
      {
        "Name": "Diana",
        "Explanation": " is a strong and powerful name that means \"goddess of the hunt.\" It is perfect for a lingerie piece that is sexy and confident.\n"
      },
      {
        "Name": "Felicity",
        "Explanation": " is a happy and joyful name that means \"happiness.\" It is a good choice for a lingerie piece that is playful and fun.\n"
      },
      {
        "Name": "Lily",
        "Explanation": " is a delicate and feminine name that means \"lily flower.\" It is perfect for a lingerie piece that is soft and romantic."
      }
    ]
  }
}
export const getName = (amid) => {

  //return mockNameResponse;
  return fetch(`https://badger-ns-sigma-1.stg.adoreme.com/api/bundles/${amid}?shuffle=true`)
    .then(response => response.json())

}

const mockBlurbResponse = {
  blurb: "Meet Petcu Mihai, a true champion and integral member of our Product Team for 7 glorious years. With unwavering dedication and unrivaled expertise, they have propelled our success to new heights. A visionary leader and master of their craft, Petcu Mihai fearlessly conquers challenges, inspires greatness, and shapes our culture of excellence. Their legacy is one of limitless achievements, setting the bar high and redefining what it means to be extraordinary."
}

export const getBlurb = (team, name, years, humbleness) => {
  //return mockBlurbResponse;
  return fetch("https://badger-ns-sigma-1.stg.adoreme.com/api/blurb", {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({
      team,
      name,
      years,
      humbleness
    }), // body data type must match "Content-Type" header
  })
    .then(response => response.json())
}

const mockMemoResponse = {
  memo: "Congratulations to Mihai Petcu for their exceptional achievements on Project 'SUP.' Over the past six months, their dedication, expertise, and surpassing results have surpassed all expectations. Their meticulous planning, innovative thinking, and flawless execution have set a new standard of excellence within the organization. Their contributions have not only propelled the project but also inspired their colleagues. Their unwavering commitment and outstanding performance have earned them the respect and admiration of peers and superiors alike. Well done!",
}

export const getMemo = (team, name, project, results) => {
  return mockMemoResponse;
  return fetch("https://jsonplaceholder.typicode.com/users/1")
    .then(response => response.json())
}
