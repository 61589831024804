import { ThemeProvider } from '@primer/react'
import { Header, Octicon, theme } from '@primer/react'
import './App.css';
import { Outlet } from "react-router-dom";
import SnitzelLogo from './assets/logo-snitzel.png';

import deepmerge from 'deepmerge'

const customTheme = deepmerge(theme, {
  fonts: {
    mono: 'MonoLisa, monospace',
  },
})
function App() {
  return (
    <ThemeProvider theme={customTheme} colorMode="dark" >
      <div id="sidebar">
        <Header sx={{ mb: 20 }}>
          <Header.Item full>
            <Header.Link href="/" fontSize={2}>
              <img className='logo' src={SnitzelLogo} />
              <span>Schnitzel</span>
            </Header.Link>
          </Header.Item>
          <Header.Item >
            <Header.Link href='/app/name-it'>NameIT</Header.Link>
          </Header.Item>
          <Header.Item >
            <Header.Link href='/app/offers-ai'>CultureB00K</Header.Link>
          </Header.Item>
          <Header.Item sx={{ mr: 0 }}>
            <Header.Link href='/app/sar'>SAR</Header.Link>
          </Header.Item>
        </Header>
      </div>
      <div id="detail">
        <Outlet />
      </div>
    </ThemeProvider>
  );
}

export default App;
