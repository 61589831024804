import { Button } from '@primer/react'
import React from 'react'
import Snitzy from '../assets/snitzy-bg.png'

const Loading = () => {
  return <div className='loading'>
    <div className='loading__spinner'>
      <img src={Snitzy} alt="" />
    </div>
  </div>
}

export default Loading
