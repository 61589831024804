import { Button, Heading } from '@primer/react'
import React from 'react'
import CopyIcon from '../icons/copy'
import SaveIcon from '../icons/save'
import SavedIcon from '../icons/saved'
import PlusIcon from '../icons/plus'
import MinusIcon from '../icons/minus'

const Name = ({ name, onSave, onShowMore, onRemove, saved }) => {
  const [copied, setCopied] = React.useState(false)
  const handleOnCopy = () => {
    navigator.clipboard.writeText(name)
    setCopied(true)
  }
  return <div>
    <Heading sx={{ fontSize: 4, mb: 5 }} as={'h1'}>{name}</Heading>
    <div className='form-input'>
      <Button onClick={handleOnCopy} leadingIcon={CopyIcon}>{copied ? 'Copied!' : 'Copy'}</Button>
      <Button onClick={() => onSave(name)} leadingIcon={saved ? SavedIcon : SaveIcon}>{saved ? 'Saved' : 'Save'}</Button>
      <Button onClick={() => onShowMore(name)} leadingIcon={PlusIcon}>More like this</Button>
      <Button onClick={() => onRemove(name)} leadingIcon={MinusIcon}>Remove</Button>
    </div>
  </div>
}

export default Name
